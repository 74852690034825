var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dataTreatmentReq.id
    ? _c(
        "app-modal",
        {
          ref: "checkDataTreatmentModal",
          attrs: {
            id: "checkDataTreatmentModal",
            size: "lg",
            noClose: "true",
            hideBtnClose: "true",
          },
        },
        [
          _c("div", { staticClass: "my-4 flex items-center" }, [
            _c("img", {
              staticClass: "hidden lg:block mt-4 w-[35%]",
              attrs: {
                src: require("../assets/imgs/advise.webp"),
                alt: "Política de privacidad, términos y condiciones",
              },
            }),
            _c(
              "article",
              { staticClass: "lg:ml-8" },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "gradient-text text-center lg:!text-left text-xl lg:!text-3xl bold pb-2",
                  },
                  [
                    _vm._v(
                      "¡Actualizamos nuestra política de privacidad, términos y condiciones!"
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "mt-3 mb-4 lg:my-4 text-center lg:!text-left text-base lg:text-lg leading-5 text-[#656466]",
                  },
                  [
                    _vm._v(
                      "Con nuestra nueva plataforma cambiamos nuestras políticas, acepta y continua vendiendo más con Refácil."
                    ),
                  ]
                ),
                _c("div", { staticClass: "flex items-start" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkDataTreatment,
                        expression: "checkDataTreatment",
                      },
                    ],
                    staticClass: "mt-[2px] lg:mt-1 accent-[#00ceee]",
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.checkDataTreatment)
                        ? _vm._i(_vm.checkDataTreatment, null) > -1
                        : _vm.checkDataTreatment,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.checkDataTreatment,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.checkDataTreatment = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkDataTreatment = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkDataTreatment = $$c
                        }
                      },
                    },
                  }),
                  _c(
                    "p",
                    {
                      staticClass:
                        "ml-3 text-base lg:text-lg leading-5 tracking-tighter",
                    },
                    [
                      _vm._v("Acepto la "),
                      _c(
                        "a",
                        {
                          staticClass: "text-[#00ABE1] hover:text-[#00ceee]",
                          attrs: {
                            href: _vm.dataTreatmentReq.form.url,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "política de privacidad, términos y condiciones"
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _c("app-error-notify", {
                  staticClass: "mt-3",
                  attrs: { error: _vm.error.message },
                }),
                _c("app-button", {
                  staticClass: "mt-4",
                  attrs: {
                    title: "Continuar",
                    clase: "primary w-full lg:w-auto",
                    isLoading: _vm.loading,
                  },
                  on: { click: _vm.handleConfirm },
                }),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }