<template>
	<app-modal
		id="checkDataTreatmentModal"
		ref="checkDataTreatmentModal"
		size="lg"
		noClose="true"
		hideBtnClose="true"
		v-if="dataTreatmentReq.id"
	>
		<!-- btnClose="false" -->
		<div class="my-4 flex items-center">
			<img
				src="../assets/imgs/advise.webp"
				alt="Política de privacidad, términos y condiciones"
				class="hidden lg:block mt-4 w-[35%]"
			/>
			<article class="lg:ml-8">
				<h2
					class="gradient-text text-center lg:!text-left text-xl lg:!text-3xl bold pb-2"
					>¡Actualizamos nuestra política de privacidad, términos y
					condiciones!</h2
				>
				<p
					class="mt-3 mb-4 lg:my-4 text-center lg:!text-left text-base lg:text-lg leading-5 text-[#656466]"
					>Con nuestra nueva plataforma cambiamos nuestras políticas, acepta y
					continua vendiendo más con Refácil.</p
				>

				<div class="flex items-start">
					<input
						type="checkbox"
						v-model="checkDataTreatment"
						class="mt-[2px] lg:mt-1 accent-[#00ceee]"
					/>
					<p class="ml-3 text-base lg:text-lg leading-5 tracking-tighter"
						>Acepto la
						<a
							:href="dataTreatmentReq.form.url"
							target="_blank"
							class="text-[#00ABE1] hover:text-[#00ceee]"
							>política de privacidad, términos y condiciones</a
						></p
					>
				</div>
				<app-error-notify
					:error="error.message"
					class="mt-3"
				></app-error-notify>
				<app-button
					title="Continuar"
					clase="primary w-full lg:w-auto"
					class="mt-4"
					:isLoading="loading"
					@click="handleConfirm"
				></app-button>
			</article>
		</div>
	</app-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import useCheckDataTreatment from '../hooks/useCheckDataTreatment';
import { AppModal, AppButton } from '@superpagos/mf-custom-components';
import AppErrorNotify from './AppErrorNotify.vue';
import { mutations } from '@superpagos/mf-store';

const { checkDataTreatment, getRequirement, updateCheckDataTreatment } =
	useCheckDataTreatment();

export default {
	name: 'AppCheckDataTreatment',

	components: {
		AppModal,
		AppButton,
		AppErrorNotify
	},

	data() {
		return {
			active: false,
			checkDataTreatment: false,
			dataTreatmentReq: {},
			error: {},
			loading: false,
			getReqIntents: 3
		};
	},

	mounted() {
		let showModal = checkDataTreatment(this.user);

		if (showModal) {
			this.getDataTreatmentReq();
		}
	},

	methods: {
		async handleConfirm() {
			this.clearError();

			if (!this.checkDataTreatment)
				return (this.error.message =
					'Debes aceptar las políticas de privacidad, términos y condiciones para continuar vendiendo.');

			let reqId = this.dataTreatmentReq.id;
			let column = this.dataTreatmentReq?.form?.column;
			let checkDataTreatmentValue = {
				[reqId]: {
					[column]: this.checkDataTreatment
				}
			};

			this.loading = true;

			const { response, error } = await updateCheckDataTreatment(
				checkDataTreatmentValue
			);
			this.loading = false;

			if (error.value) {
				return this.appNotify.error(error.value?.message);
			}

			if (response?.errors?.length) return (this.error = response.errors[0]);

			this.$refs.checkDataTreatmentModal.hide();

			let userAux = {
				...this.user,
				checkDataTreatment: this.checkDataTreatment
			};

			this.$store.commit(mutations.SET_USER, userAux);

			this.appNotify.success(
				'¡Gracias por aceptar las políticas de privacidad, términos y condiciones!'
			);
		},
		async getDataTreatmentReq() {
			let { dataTreatmentReq, error } = await getRequirement();
			if (error?.value) {
				// Condición de parada
				if (this.getReqIntents === 0) {
					return;
					// this.appNotify.error(
					// 	'Ha ocurrido un error, intenta más tarde.'
					// );
				}

				// Limpiar el timeOut anterior
				if (this.getReqTimeout) {
					clearTimeout(this.getReqTimeout);
				}

				// Intentar de nuevo
				this.getReqTimeout = setTimeout(() => {
					this.getReqIntents -= 1;
					this.getDataTreatmentReq();
				}, 1000);
			}

			this.dataTreatmentReq = dataTreatmentReq;

			// Mostrar modal
			setTimeout(() => {
				this.$refs.checkDataTreatmentModal.show();
			}, 500);
		},
		clearError() {
			this.error = {};
		}
	},

	computed: {
		...mapGetters(['user'])
	},

	watch: {
		checkDataTreatment() {
			this.clearError();
		},
		'user.checkDataTreatment': {
			handler(value) {
				if (this.user && !value) {
					this.getDataTreatmentReq();
				}
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
input[type='checkbox']:checked:before {
	background-color: #00ceee;
}

.gradient-text {
	background-color: #00ceee;

	background-image: linear-gradient(45deg, #00ceee, #722ae6);

	background-size: 100%;
	background-repeat: repeat;
	background-clip: text;

	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
}

@media (min-width: 768px) {
	.gradient-text {
		font-size: 1.875rem !important;
	}
}
</style>
