var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-login" }, [
          _c("div", { staticClass: "logo-login mx-auto" }, [
            _c("img", { attrs: { src: _vm.brand.logo, width: "100px" } }),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "mt-4" }, [
            _c(
              "div",
              { staticClass: "relative" },
              [
                _c("AppInput", {
                  attrs: {
                    type: _vm.show_pass ? "text" : "password",
                    placeholder: "Ej: Colombia123",
                    labelClass: "label-simple",
                  },
                  model: {
                    value: _vm.form.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "password", $$v)
                    },
                    expression: "form.password",
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "disabled-key",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        _vm.show_pass = !_vm.show_pass
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v(
                        _vm._s(_vm.show_pass ? "block" : "remove_red_eye")
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box-btn mx-auto my-4" },
              [
                _c("app-button", {
                  attrs: {
                    clase: "primary w-auto px-5",
                    title: "Cambiar contraseña",
                    type: "submit",
                    isLoading: _vm.loading,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "h1",
      { staticClass: "login-title text-center text-2xl leading-6" },
      [_c("strong", [_vm._v("Primero cambia tu contraseña")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }