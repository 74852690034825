<template>
	<div>
		<form v-on:submit.prevent="login">
			<div class="text-center flex flex-column items-center">
				<h1>{{ message }}</h1>
				<p class="font-bold text-base leading-5">Ingresa tus datos para poder acceder</p>
				<div class="mt-4">
					<div class="form-group">
						<div class="input-group" style="position: relative">
							<div class="w-[270px]">
								<AppInput :type="show_pass ? 'text' : 'password'" v-model="form.password" autocomplete="off"
									label="Contraseña" class="w-full" placeholder="••••••••"
									extraClass="input-data-provider" labelClass="label-data-provider" />
							</div>
							<button type="button" class="btn disabled-key !absolute !top-9"
								v-on:click="show_pass = !show_pass">
								<img v-if="show_pass" src="@/assets/imgs/login/open.svg" alt="" />
								<img v-else src="@/assets/imgs/login/hide.svg" alt="" />
							</button>
						</div>
					</div>
					<div class="form-group" v-if="needToken">
						<div class="input-group">
							<span class="input-group-addon"><i class="material-icons">vpn_key</i></span>
							<AppInput type="text" placeholder="Token" v-model="form.token" />
						</div>
					</div>
					<!-- <div class="form-group" v-if="needCoordinate">
            <div class="input-group">
              <span class="input-group-addon"><i class="material-icons">vpn_key</i></span>
              <input type="text" class="form-control" :placeholder="'Coordenada x='+coodinates[0]+' y='+coodinates[1] "  v-model="form.coordinateCode">
            </div>
          </div>             -->
					<div class="text-center mt-4">

						<app-button width="16rem" type="submit" clase="primary px-4" title="Iniciar sesión"
							:isLoading="loading">
						</app-button>
					</div>
				</div>
			</div>
		</form>
		<AppModal size="xl" id="2fa" ref="twoFactAuthModal">
			<div>
				<two-fact-auth> </two-fact-auth>
			</div>
		</AppModal>
		<AppModal size="xl" id="changePasswordModal" ref="changePasswordModal">
			<div>
				<reset-password @2fa="showNeed2faModal()"></reset-password>
			</div>
		</AppModal>
		<AppModalSession v-if="user" :message="messageSession" :username="user.username" ref="modalSession" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
// import { LOGIN, CHECK, FETCH_USER_CHILDREN } from '@/store/actions.type';
import { actions } from '@superpagos/mf-store';
import { GLOBAL_VERSION } from '@/common/config';
import { getHashComputer, AuthService } from '@superpagos/mf-services';
import {
	AppButton,
	AppModal,
	AppInput
} from '@superpagos/mf-custom-components';
import TwoFactAuth from '../2facAuth.vue';
import ResetPassword from '../resetPassword.vue';
import platform from 'platform';
import { disconnectSocket } from '../../lib/socket';
import AppModalSession from './AppModalSession.vue';

import * as singleSpa from 'single-spa';

export default {
	name: 'app-login',
	props: {
		message: { type: String, required: false }
	},
	data() {
		return {
			loading: false,
			show_pass: false,
			needToken: false,
			form: { geo: {}, saveSession: false, token: '' },
			formForgot: { user: '' },
			showing: false,
			messageSession: ''
		};
	},
	components: {
		AppButton,
		TwoFactAuth,
		AppModal,
		ResetPassword,
		AppInput,
		AppModalSession
	},
	methods: {
		getOSInfo() {
			let info = platform.parse(window.navigator.userAgent);
			return ({ os: info.os.family, osVersion: info.os.version || "Unknown Version" });
		},
		navigateTo(path) {
			singleSpa.navigateToUrl(path);
		},
		next() {
			if (this.loading) return;
			this.loading = true;
			this.showing = true;
			let data = {
				username: this.user.username,
				geo: {}
			};
			this.$store
				.dispatch(actions.CHECK, data)
				.then(result => {
					this.needToken = result.needToken;
					this.needCoordinate = result.needCoordinate;
					if (result?.redirect && result?.redirect?.type !== 'PDV') {
						return (window.location.href = result?.redirect?.url);
					}
				})
				.catch(error => {
					this.appNotify.error(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		async login() {
			let channel = window.localStorage.getItem('channel');
			let route = '';
			const osInfo = this.getOSInfo();
			try {
				disconnectSocket();
				this.loading = true;
				this.form.username = this.user.username;
				let data = {
					...this.form,
					_channel: channel === 'app' ? 'app' : 'web',
					osName: osInfo.os,
					osVersion: osInfo.osVersion,
					device: {
						key: await getHashComputer(),
						brand: window.location.hostname,
						carrier: '',
						uniqueId: null,
						version: GLOBAL_VERSION,
						model: '',
						deviceId: null,
						appName: window.location.hostname,
						serial: '',
						number: ''
					}
				};

				let user = await this.$store.dispatch(actions.LOGIN, data);
				this.$store.dispatch(actions.FETCH_USER_CHILDREN, {
					force: 0,
					userExt: null
				});

				let dataUser = AuthService.getUser()

				if (dataUser) {
					AuthService.saveUser(user)
				}

				this.loading = false;
				this.showing = false;
				this.appNotify.success(`¡Hola! Bienvenido ${user.name}`);
				var need2fa = user.needToken && !this.form.token;
				if (need2fa) {
					this.$refs.twoFactAuthModal.show();
					// window.localStorage.setItem('need2fa', need2fa);
				}
				this.form = { geo: {} };
				this.$emit('close');
				if (user.resetPass) {
					// this.$router.push({ name: 'reset' });
					this.$refs.changePasswordModal.show();
				} else {
					if (need2fa) {
						this.$refs.twoFactAuthModal.show();
						// this.$router.push({ name: '2fa' });
					} else {
						route =
							this.user.modules.findIndex(({ name }) => name == 'sells') != -1
								? '/'
								: '/refacilpay/';
						this.navigateTo(route);
						// this.$router.push({ name: 'home', params: { showVideo: true } });
					}
				}
			} catch (error) {
				if (error.code === '405') {
					this.messageSession = error.message;
					this.$refs.modalSession.show();
				} else {
					this.loading = false;
					this.appNotify.error(error.message);
				}
			} finally {
				this.loading = false;
			}
		},
		showNeed2faModal() {
			this.$refs.twoFactAuthModal.show();
		}
	},
	computed: {
		...mapGetters(['user'])
	}
};
</script>

<style lang="scss">
.login-general {
	background: transparent !important;
	width: 100% !important;
	margin-left: 0px !important;
	padding: 10px;
	position: initial;
}
</style>
