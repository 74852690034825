<template>
	<div>
		<app-modal size="lg" id="appPopUpModal" ref="appPopUpModal" modalClass="modal-bg-transparent"
			hideBtnClose="true">
			<div class="appPopUpModal flex justify-center items-center mb-12 md:mb-0">
				<button class="appPopUpModal__close flex justify-center items-center" @click="close">
					<i class="material-icons">close</i>
				</button>
				<img v-if="popUpInfo" class="appPopUpModal__img max-h-[80vh] w-auto" :src="popUpInfo.image"
					alt="Imagen promocional popup" width="73%" />
				<!-- <a href="https://forms.gle/btgnYCnjrC1o5o8e8" target="_blank" class="cta-button">
					<p class="block md:none textMobile">
						Inscríbete
					</p>
					<p class="none md:block textDesktop">Inscríbete aquí</p>
				</a> -->
			</div>
		</app-modal>
	</div>
</template>

<script>
import { AppModal, } from '@superpagos/mf-custom-components';
import usePopUpModal from './hooks/usePopUpModal';
import { mapGetters } from 'vuex';
import { attributesReducer } from '@superpagos/mf-store';

const {
	// validateShowModal, 
	getPopUpModal } = usePopUpModal();
export default {
	name: 'AppPopUpModal',

	data() {
		return {
			homeRoutePath: '/',
			popUpInfo: null
		};
	},

	mounted() {
		// this.showModalLogic();
		this.validatePopUpOfView()
	},

	components: {
		AppModal,
	},

	methods: {
		open() {
			this.$refs.appPopUpModal.show();
		},
		close() {
			this.$refs.appPopUpModal.hide();
		},
		async showModalLogic({ popUpPayload }) {
			try {
				const popUpSource = await getPopUpModal(popUpPayload)
				if (!popUpSource) return

				this.popUpInfo = popUpSource;

				// let pathname = window.location.pathname;
				// if (!(pathname == this.homeRoutePath && this.user)) return;
				setTimeout(() => {
					// let showModal = validateShowModal();
					// showModal && this.open();
					this.open();
				}, 1000);
			} catch (e) {
				// TODO: Handle error
			}
		},
		validatePopUpOfView() {
			let url = new URL(window.location.href);
			let pathName = url.pathname // current view
			attributesReducer.requestPopUp({view: pathName || '/'})
		}
	},

	computed: {
		...mapGetters(['user', 'requestAttributesPayload'])
	},

	watch: {
		'$route.path'(value) {
			attributesReducer.requestPopUp({view: value})
		},
		"requestAttributesPayload": {
			handler: function (value) {
				if (!value) return
				this.showModalLogic({ popUpPayload: value });
			},
			deep: true
		}
	}
};
</script>

<style lang="scss" scoped>
.appPopUpModal {
	position: relative;

	&__close {
		position: absolute;
		top: 0px;
		right: 0px;
		padding: 16px;
		cursor: pointer;
		width: 21px;
		height: 21px;
		border-radius: 50%;
		background-color: #000;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

		// transition: all 0.3s ease-in-out;
		&:hover {
			transform: scale(1.01);
		}

		i {
			color: #fff;
			font-size: 15px;
		}
	}

	.cta-button {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		bottom: 10%;
		right: 0;
		left: 15%;
		padding: 4px;
		cursor: pointer;
		width: 40%;
		height: 40px;
		border-radius: 12px;
		background-color: #E8BB19;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
		color: '#fff';
		transition: all 0.5s;

		p {
			color: #fff;
			font-weight: bold;
			font-size: 20px;
		}
	}
}


.cta-button:hover {
	transform: scale(1.05);
}

@keyframes glow {
	0% {
		box-shadow: 0 0 10px #FFD700;
	}

	50% {
		box-shadow: 0 0 20px #FFD700;
	}

	100% {
		box-shadow: 0 0 10px #FFD700;
	}
}

@keyframes fadeInAnimation {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.cta-button {
	opacity: 0;
	animation: glow 1s infinite alternate, fadeInAnimation 2s ease forwards;
}


@media screen and (max-width: 700px) {
	.cta-button {
		p {
			font-size: 20px;
		}
	}

	.textMobile {
		display: block;
	}

	.textDesktop {
		display: none;
	}
}

@media screen and (min-width: 701px) {
	.cta-button {
		p {
			font-size: 30px;
		}
	}

	.textMobile {
		display: none;
	}

	.textDesktop {
		display: block;
	}
}
</style>
