import { useApi } from '@superpagos/mf-services';
import Store, { mutations } from '@superpagos/mf-store';

const API = 'core';

const saveBrand = brand => {
	Store.commit(mutations.SET_BRAND, brand);
};

const useBrand = () => {
	const getBrand = async () => {
		const location = window.location.host;
		const { request, response, error } = useApi(API);

		await request('/brand/get', { location });
		if (!error?.value && response.value) {
			// Set brand in store
			saveBrand(response.value);
		}

		// Si viene la marca en la respuesta, la retornamos, sino utilizamos la que tenemos en el store
		// que a su vez consulta si habiamos guardado una marca en el local storage, o si no, usa la default
		return {
			brand: response.value ?? Store.state.auth.brand,
			error: error.value
		};
	};

	const getBrandVersionWeb = async () => {
		const location = window.location.host;
		let versionWeb = '';
		const { request, response, error } = useApi(API);

		await request('/brand/get', { location });
		if (!error?.value && response.value) {
			versionWeb = response.value?.versionWeb || '';
		}

		// Si viene la marca en la respuesta, la retornamos, sino utilizamos la que tenemos en el store
		// que a su vez consulta si habiamos guardado una marca en el local storage, o si no, usa la default
		return {
			versionWeb: versionWeb ?? Store.state.auth.brand?.versionWeb,
			error: error.value
		};
	};

	return { getBrand, getBrandVersionWeb };
};

export default useBrand;
