import services, { MixinService } from "@superpagos/mf-services";

export const initServices = ({ vue, store }) => {
    vue.mixin({ methods: MixinService });

    services.ApiService.init(store);
    services.ApiServiceRefacilOld.init(store);
    services.ApiServiceSell.init(store);
    services.ApiServiceRequirement.init(store);
    services.ApiServiceAdmin.init(store);

    // ApiService.setVue(mount());
    services.ApiService.setVue(vue);
    services.ApiServiceRefacilOld.setVue(vue);
    services.ApiServiceSell.setVue(vue);
    services.ApiApagarService.setVue(vue);
    services.ApiServiceRequirement.setVue(vue);
}