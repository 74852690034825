export const Enviroment = {
	production: {
		SERVER: 'https://plataforma.qa.refacil.co',
		URL_REGISTER: 'https://autoregistro.qa.refacil.co/register',
		SOCKET_URL: 'https://socket.qa.refacil.co',
		REFACIL1_URL: 'https://qa.back.refacil.co/'
	},
	preproduction: {
		SERVER: 'https://plataforma.qa.refacil.co',
		URL_REGISTER: 'https://autoregistro.qa.refacil.co/register',
		SOCKET_URL: 'https://socket.qa.refacil.co',
		REFACIL1_URL: 'https://qa.back.refacil.co/'
	},
	testing: {
		SERVER: 'https://plataforma.qa.refacil.co',
		URL_REGISTER: 'https://autoregistro.qa.refacil.co/register',
		SOCKET_URL: 'https://socket.qa.refacil.co',
		REFACIL1_URL: 'https://qa.back.refacil.co/'
	},
	development: {
		SERVER: 'https://plataforma.qa.refacil.co',
		URL_REGISTER: 'https://autoregistro.qa.refacil.co/register',
		SOCKET_URL: 'https://socket.qa.refacil.co',
		REFACIL1_URL: 'https://qa.back.refacil.co/'
	}
};
