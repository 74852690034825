var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "app-modal",
        {
          ref: "appPopUpModal",
          attrs: {
            size: "lg",
            id: "appPopUpModal",
            modalClass: "modal-bg-transparent",
            hideBtnClose: "true",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "appPopUpModal flex justify-center items-center mb-12 md:mb-0",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "appPopUpModal__close flex justify-center items-center",
                  on: { click: _vm.close },
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
              ),
              _vm.popUpInfo
                ? _c("img", {
                    staticClass: "appPopUpModal__img max-h-[80vh] w-auto",
                    attrs: {
                      src: _vm.popUpInfo.image,
                      alt: "Imagen promocional popup",
                      width: "73%",
                    },
                  })
                : _vm._e(),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }