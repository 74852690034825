<template>
	<div class="validate-pin-dialog">
		<div class="box-add-data text-center">
			<h1 class="bold">¡Gracias por confiar en nosotros!</h1>
			<p class="mt-5 vpd-p1">
				Hemos enviado un <strong>PIN</strong> a tu celular
				<template v-if="errConfirm && errConfirm.data"
					><strong>({{ errConfirm.data.cellphoneMask }})</strong></template
				>
				y a tu correo, escríbelo acá
			</p>

			<div class="py-[1rem] md:py-[1rem]">
				<app-otp-input v-model="form.pin"
					:primaryColor="'var(--primaryColor)'"
					:otp-error="otpError"
					:otp-loading="loading"
					@sendCode="resendPin" />
			</div>

			<div class="flex flex-row justify-around box-btn mx-auto">
				<app-button
					extraClass="neutral w-auto px-5 mr-4"
					clase="secondary"
					v-on:click="close"
					title="Lo haré luego"
					width="122px"
				/>

				<app-button
					extraClass="primary-gradient w-auto px-5"
					clase="primary"
					v-on:click="confirmPin"
					title="Confirmar"
					width="119px"
					:isLoading="loading"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { actions } from '@superpagos/mf-store';
import { AppButton, AppOtpInput } from '@superpagos/mf-custom-components';

export default {
	name: 'app-validate-pin',
	data() {
		return {
			loading: false,
			form: { pin: null },
			timer: 0,
			errConfirm: null,
			thread: null,
      otpError: null
		};
	},
	components: {
    AppOtpInput,
		AppButton
	},
	mounted() {},
	methods: {
		confirmPin() {
			this.loading = true;
			this.$store
				.dispatch(actions.CONFIRM_DEVICE, this.form)
				.then(result => {
					this.close();
          this.appNotify.success(result.message);
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.errConfirm = err;
          this.otpError = err.message
					this.appNotify(err.message);
				});
		},
		setError(error) {
			this.errConfirm = error;
		},
		resendPin() {
			this.$store
				.dispatch(actions.DEVICE_SEND_PIN, {})
				.then(result => {
          this.appNotify.success(result.message);
				})
				.catch(err => {
          this.appNotify.error(err.message);
				});
		},
		close() {
			this.$emit('close');
		}
	},
	computed: {
		...mapGetters(['user'])
	}
};
</script>

<style lang="scss">
.box-add-data {
	background: #fff;
	border-radius: 20px;
	bottom: 135px;
	padding: 20px;
	left: 35px;
}
.box-btn-large {
	button {
		width: 150px;
	}
}

.validate-pin-dialog {
	/**
  * Mobile only styles
  */
	@media (max-width: 768px) {
		.vpd-p1 {
			margin: 0 !important;
			padding-top: 1rem;
			// text
			font-size: 16px;
			color: #000;
		}

		.flex .buttons {
			line-height: 1;

			button {
				display: block;
			}
		}
	}

	@media (max-width: 500px) {

		h1 {
			font-size: 20px;
		}
	}
}
</style>
