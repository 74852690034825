import auth from '@/middleware/auth';

const LoginView = () => import('@/views/Login.vue');
const RecoverPass = () => import('@/views/RecoverPass.vue');
const BillView = () => import('@/views/movements/BillView.vue');
const AppSidebar = () => import('@/components/SideNavbar.vue');
const DashboardView = () => import('@/views/Dashboard.vue');
const ProfileView = () => import('@/views/Profile/Profile.vue');
// const AccountView = () =>
// 	import('@/views/Profile/accountViews/AccountView.vue');
const AccountView = () =>
	import('@/views/Profile/accountViews/AccountView.vue');

const ConfigView = () => import('@/views/Profile/configViews/ConfigView.vue');
const SeconPass = () => import('@/views/Profile/configViews/SecondPass.vue');
const Sessions = () => import('@/views/Profile/configViews/Sessions.vue');
const ChangePass = () => import('@/views/Profile/configViews/ChangePass.vue');
const SecondFaView = () => import('@/views/2faView.vue');
const RequirementView = () => import('@/views/Requirement.vue');
const Publicity = () => import('@/views/inConstruction/Publicity.vue');
const Tutorials = () => import('@/views/TutorialsView.vue');
const Market = () => import('@/views/inConstruction/MarketPlace.vue');
const ActiveChargeMaxTip = () => import('@/views/auth/ActiveChargeMaxTip.vue');
//const ReferralsView = () => import('@/views/ReferralsView.vue');
const AdminView = () => import('@/views/admin');
const MyCommissions = () => import('@/views/MyCommissions');

// Notifications screen
const NotificationsScreen = () => import('@/views/notificationsScreen');

const routes = [
	{
		path: '/login',
		name: 'signin',
		// component: LoginView,
		component: LoginView
	},
	{
		path: '/recover/password/:userId/:token',
		component: RecoverPass
	},
	{
		path: '/factura/:userId/:saleId',
		component: BillView
	},
	{
		path: '/',
		component: AppSidebar,
		meta: { middleware: [auth] },
		children: [
			{
				// UserProfile will be rendered inside User's <router-view>
				// when /user/:id/profile is matched
				path: '/',
				component: DashboardView,
				meta: { middleware: [auth] }
			},
			// {
			// 	path: '/profiles/account',
			// 	name: 'profileAccount',
			// 	component: AccountView,
			// 	meta: { middleware: [auth] },
			// 	children: [
			// 		{
			// 			path: '/',
			// 			component: AccountView,
			// 			meta: { middleware: [auth] }
			// 		},
			// 		{
			// 			path: 'test',
			// 			component: TestView,
			// 			meta: { middleware: [auth] }
			// 		}
			// 	]
			// },
			{
				path: '/profile',
				name: 'profile',
				component: ProfileView,
				meta: { middleware: [auth] },
				children: [
					{
						path: '/',
						component: ProfileView,
						meta: { middleware: [auth] }
					},
					{
						path: 'account',
						name: 'account',
						component: AccountView,
						meta: { middleware: [auth] },
						props: route => ({ ...route.params })
					},
					{
						path: 'configuration',
						name: 'configuration',
						component: ConfigView,
						meta: { middleware: [auth] },
						props: route => ({ ...route.params })
					}
				]
			},
			{
				path: '/secondPass',
				component: SeconPass,
				meta: { middleware: [auth] }
			},
			{
				path: '/sessions',
				component: Sessions,
				meta: { middleware: [auth] }
			},
			{
				path: '/changePass',
				component: ChangePass,
				meta: { middleware: [auth] }
			},
			{
				path: '/activateToken',
				component: SecondFaView,
				meta: { middleware: [auth] }
			},
			{
				name: 'requirement',
				path: '/requirement',
				component: RequirementView,
				meta: { middleware: [auth] },
				props: true
			},
			{
				name: 'requirementId',
				path: '/requirement/:id',
				component: RequirementView,
				meta: { middleware: [auth] },
				props: true
			},
			{
				name: 'publicity',
				path: '/publicity',
				component: Publicity,
				meta: { middleware: [auth] },
				props: true
			},
			{
				name: 'tutorials',
				path: '/tutoriales',
				component: Tutorials,
				meta: { middleware: [auth] },
				props: true
			},
			{
				name: 'market',
				path: '/market',
				component: Market,
				meta: { middleware: [auth] },
				props: true
			},
			{
				name: 'ActiveChargeMaxTip',
				path: '/ActiveChargeMaxTip',
				component: ActiveChargeMaxTip,
				meta: { middleware: [auth] },
				props: true
			},
			/*{
				name: 'referrals',
				path: '/referidos',
				component: ReferralsView,
				meta: { middleware: [auth] }
			},*/
			{
				name: 'admin',
				path: '/admin',
				component: AdminView,
				meta: { middleware: [auth] }
			},
			{
				name: 'myCommissions',
				path: '/myCommissions',
				component: MyCommissions,
				meta: { middleware: [auth] }
			},
			{
				name: 'notificationsScreen',
				path: '/notifications',
				component: NotificationsScreen,
				meta: { middleware: [auth] }
			}
		]
	},
	{
		path: '/*',
		component: AppSidebar
	}
];

export default routes;
