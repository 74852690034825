<template>
	<app-input
		extraClass="input-data-provider"
		labelClass="label-data-provider"
		:name="field.name || ''"
		:label="field.form.label"
		:placeholder="field.form.placeholder"
		:type="type || field.form.type"
		:value="
			field.form.value && field.form.value[0] && field.form.value[0].value
		"
		:disabled="isDisabled"
		:link="field.form.url"
		:text="field.name"
		:length="field.form.maxlength"
		:error="error || ''"
		@file="changeFile"
		@input="updateValue"
	>
		<template v-if="field.form.type == 'select' || type == 'select'">
			<option
				v-for="option in options"
				:key="option.value"
				:value="option.value"
			>
				{{ option.label }}
			</option>
		</template>
	</app-input>
</template>

<script>
import {
	AppInput
	// AppButton,
	// AppModal,
} from '@superpagos/mf-custom-components';
import { useApi } from '@superpagos/mf-services';
export default {
	name: 'ToDoInput',
	props: ['field', 'error', 'type', 'metaMapStatus', 'userRol'],
	components: {
		AppInput
	},
	data() {
		return {
			value: '',
			file: {},
			options: [],
			requirementDisabled: [1, 2, 3, 4, 9, 11, 12, 25, 26], // Usado para validaciones de MetaMap
            fieldsDisabledByRole: [1, 2, 3, 4, 9, 10, 11, 12, 25, 26, 49], // Usado para validaciones de rol
		};
	},

	mounted() {
		this.options =
			this.field.form.type == 'select' ? this.field.form.options : [];
		this.fetchEnpoint();
	},

	methods: {
		changeFile(file) {
			this.file = file;
		},
		updateValue(value) {
			this.value = value;
		},
		async fetchEnpoint() {
			if (this.field.form.endpoint) {
				let API = 'core';
				let endpoint = this.field.form.endpoint.url;
				const { response, request } = useApi(API);
				await request(endpoint, {});
				this.options = response.value.banks;
			}
		}
	},

	watch: {
		value: {
			deep: true,
			handler(f) {
				this.$emit('value', f);
			}
		},
		file: {
			deep: true,
			handler(f) {
				this.$emit('file', f);
			}
		}
	},

	computed: {
        isDisabled() {
            if (!this.canEditBasedOnRole) {
                return this.fieldsDisabledByRole.includes(this.field.id);
            }
            return this.isDisabledBasedOnMetaMap;
        },

        canEditBasedOnRole() {
            return [1, 2, 3, 4, 649, 10004].includes(this.userRol?.response?.rolId);
        },

        isDisabledBasedOnMetaMap() {
            if (this.metaMapStatus?.form?.value[1] && 
                !['rejectedDifference', 'rejected', 'expired'].includes(this.metaMapStatus.form.value[1].status)) {
                return this.requirementDisabled.includes(this.field.id);
            }
            return false;
        }
    }
};
</script>

<style lang="scss" scoped></style>
