var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container mt-5 2fa",
      staticStyle: { "text-align": "center" },
    },
    [
      _vm.step == 1
        ? [
            _vm.qr
              ? [
                  _c("div", { staticClass: "row content" }, [
                    _c("div", { staticClass: "col-md-4" }, [
                      _c("img", {
                        attrs: {
                          src:
                            "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=" +
                            this.qr,
                          width: "200px",
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-8 instructions" },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "bold text-md tracking-tighter leading-none",
                          },
                          [
                            _vm._v(
                              " Escanea este código de barras con Authy o Google Authenticator "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "font-thin text-base text-center mt-3",
                          },
                          [
                            _vm._v(
                              " Con el fin de brindarle mayor seguridad en nuestra plataforma; lo invitamos a que descargue desde la play store o app store, el segundo método de seguridad llamado token; las aplicaciones recomendadas son Authy o Google Authenticator, este método consiste en generar un código dinámico el cual le brindará seguridad en cada acceso. "
                            ),
                          ]
                        ),
                        _c(
                          "app-button",
                          {
                            attrs: {
                              extraClass: "primary-gradient px-5 my-5",
                              clase: "primary w-auto",
                              title: "Continuar",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.next()
                              },
                            },
                          },
                          [[_vm._v(" Continuar ")]],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              : [
                  _c("h1", [
                    _vm._v(
                      "Te estamos generando un Token, por favor espere..."
                    ),
                  ]),
                  _c("app-loader"),
                ],
          ]
        : _vm.step == 2
        ? [
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.verify.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("h1", [_vm._v("Coloca el código")]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.token,
                            expression: "form.token",
                          },
                        ],
                        staticClass: "form-control input-token box-input",
                        attrs: { type: "text" },
                        domProps: { value: _vm.form.token },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "token", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c(
                      "app-button",
                      {
                        attrs: {
                          extraClass: "primary-gradient",
                          clase: "primary w-auto mt-4 primary-gradient ",
                          title: "Verificar",
                          type: "submit",
                        },
                      },
                      [[_vm._v(" Verificar ")]],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }