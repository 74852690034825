var env = 'production';
// var env = "development";
// var env = 'testing';
var server = '';
var urlRegister = '';
// var server = "http://192.168.88.154:9091";

if (env == 'production') {
	server = 'https://plataforma.qa.refacil.co';
	urlRegister = 'https://autoregistro.qa.refacil.co/register';
} else if (env == 'testing') {
	server = 'https://testing.refacil.co';
	urlRegister = 'https://front.autoregistro.prep.refacil.co/register';
} else if (env == 'development') {
	server = 'http://192.168.88.36:9091/';
	urlRegister = 'https://front.autoregistro.prep.refacil.co/register';
}

export const ENV = env;

export const API_URL = server;

export const BASE_URL = server;

export const GLOBAL_VERSION = '1.13.1';

export const PLATFORM = 'WEB';

export const WSS = 'prep.refacil.co';

export const URL_REGISTER = urlRegister;

export default API_URL;
