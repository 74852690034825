var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isSpecialRoute ? _c("router-view") : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "unauthorizedModal",
          attrs: {
            id: "unauthorizedModal",
            centered: "",
            size: "sm",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header": "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "div",
            { staticClass: "text-center h-full flex flex-col" },
            [
              _c("h2", { staticClass: "text-2xl bold" }, [
                _vm._v("¡Se cerró tu sesión!"),
              ]),
              _vm.unauthorizedError.message
                ? [
                    _c("p", { staticClass: "my-4" }, [
                      _vm._v(_vm._s(_vm.unauthorizedError.message)),
                    ]),
                  ]
                : _vm._e(),
              _c(
                "div",
                { staticClass: "mt-auto mb-auto" },
                [
                  _c("app-button", {
                    staticStyle: { width: "auto" },
                    attrs: {
                      clase: "primary",
                      title: "Ir al inicio de sesión",
                    },
                    on: { click: _vm.goToLogin },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "modalLogin",
          attrs: {
            id: "modalLogin",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            size: "sm",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            static: true,
            "body-class": "py-5",
          },
        },
        [
          _c("app-login", {
            ref: "loginComponent",
            attrs: { message: _vm.unauthorizedError.message },
            on: {
              close: function ($event) {
                return _vm.$refs.modalLogin.hide()
              },
            },
          }),
          _c("div", { staticClass: "text-center mt-2" }, [
            _c(
              "span",
              {
                staticClass:
                  "text-center text-sm tracking-tighter p-0 fw-light",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.goToLogin },
              },
              [_vm._v(" Inicia sesión en una cuenta diferente ")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modalConfirmPin",
          attrs: {
            id: "modalConfirmPin",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            "hide-footer": "",
            "hide-header": "",
            size: "lg",
            "modal-class": "modal-bg-transparent",
            static: true,
          },
        },
        [
          _c("app-validate-pin", {
            ref: "validatePinC",
            on: { close: _vm.closeModalConfirmPin },
          }),
        ],
        1
      ),
      !_vm.whatsappVerify
        ? _c("app-whatsapp-fab", {
            attrs: { "phone-number": _vm.contactCellphone },
          })
        : _vm._e(),
      _c("check-data-treatment"),
      _c("app-pop-up-modal"),
      _c("BrandUpdate"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }