<template>
	<div class="container mt-5 2fa" style="text-align: center">
		<template v-if="step == 1">
			<template v-if="qr">
				<div class="row content">
					<div class="col-md-4">
						<!-- <img :src="'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl='+this.qr+'&chld=L|1&choe=UTF-8'"> -->
						<img
							:src="
								'https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=' +
								this.qr
							"
							width="200px"
						/>
					</div>
					<div class="col-md-8 instructions">
						<h2 class="bold text-md tracking-tighter leading-none">
							Escanea este código de barras con Authy o Google Authenticator
						</h2>
						<p class="font-thin text-base text-center mt-3">
							Con el fin de brindarle mayor seguridad en nuestra plataforma; lo
							invitamos a que descargue desde la play store o app store, el
							segundo método de seguridad llamado token; las aplicaciones
							recomendadas son Authy o Google Authenticator, este método
							consiste en generar un código dinámico el cual le brindará
							seguridad en cada acceso.
						</p>
						<!-- <app-button extraClass="primary-gradient mt-3" v-on:click="next()">
							Continuar
						</app-button> -->
						<app-button
							extraClass="primary-gradient px-5 my-5"
							v-on:click="next()"
							clase="primary w-auto"
							title="Continuar"
						>
							<!-- <template v-if="loading">
					<app-loader />
				</template> -->
							<template> Continuar </template>
						</app-button>
					</div>
				</div>
			</template>
			<template v-else>
				<h1>Te estamos generando un Token, por favor espere...</h1>
				<app-loader />
			</template>
		</template>
		<template v-else-if="step == 2">
			<form v-on:submit.prevent="verify">
				<div>
					<h1>Coloca el código</h1>
					<div class="form-group">
						<input
							type="text"
							class="form-control input-token box-input"
							v-model="form.token"
						/>
					</div>

					<app-button
						extraClass="primary-gradient"
						clase="primary w-auto mt-4 primary-gradient "
						title="Verificar"
						type="submit"
					>
						<!-- <template v-if="loading">
					<app-loader />
				</template> -->
						<template> Verificar </template>
					</app-button>
				</div>
			</form>
		</template>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { actions } from '@superpagos/mf-store';
import { AppButton } from '@superpagos/mf-custom-components';
// import { GENERATE_2FA_TOKEN, VERIFY_2FA_TOKEN } from "@/store/actions.type";
export default {
	name: '2facAuth',
	data() {
		return {
			qr: '',
			step: 1,
			form: {
				token: ''
			}
		};
	},
	mounted() {
		this.fetchToken();
	},
	computed: {
		...mapGetters(['brand'])
	},
	components: {
		AppButton
	},
	methods: {
		fetchToken() {
			this.$store.dispatch(actions.GENERATE_2FA_TOKEN).then(token => {
				this.qr = token.otpauth_url;
			});
		},
		next() {
			this.step++;
		},
		verify() {
			this.$store
				.dispatch(actions.VERIFY_2FA_TOKEN, this.form)
				.then(() => {
					window.localStorage.removeItem('need2fa');
					// this.$router.push({ name: "topup" });
					// this.$snotify.success(result.message);
				})
				.catch(error => {
					console.error(error);
					// this.$snotify.error(error.message);
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.content {
	display: flex;
	align-items: center;
	max-height: 80%;
}
.input-token {
	padding: 25px;
}
.container {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;
	width: 100%;
}

@media (max-width: 767px) {
	.instructions {
		margin: 40px 0;
	}
}
</style>
