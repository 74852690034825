<template>
    <b-modal id="reviewModalSession" no-close-on-backdrop no-close-on-esc="" centered hide-footer hide-header
        ref="refReviewModalSession" size="lg" modal-class="modal-bg-transparent">
        <div class=" box-add-data">
            <div class="col-12 row p-0 m-0">
                <div class="col-5 p-0 d-flex justify-content-center">
                    <img src="@/assets/imgs/login/interrogacionModal.png" alt="interrogacion" style="height:177px"
                        class="mr-2" />
                </div>
                <div class="col-7 p-0 m-0 row">
                    <h3 class="appInfo-title-secondary text-[28px] font-bold leading-11 mt-2 text-left ">
                        ¡Ups!<br>
                        Debes cerrar sesión en tus otros dispositivos
                    </h3>
                    <p class="font-normal text-base text-[#000000] text-[24px] m-0 mr2 sm:text-xs">
                        {{ message }}
                    </p>
                    <div class="col-12 row justify-content-center p-0">
                        <div class="col-7 row justify-content-center p-0">
                            <app-button extraClass="primary-gradient px-3 my-5" v-on:click="closeSession"
                                clase="primary" title="Cerrar sesiones" >
                            </app-button>
                        </div>
                        <div class="col-5 row justify-content-center">
                            <app-button extraClass="primary-gradient  px-6 my-5" v-on:click="close"
                                clase="secondary-outline" title="Cancelar" width="50px">
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { AppButton } from '@superpagos/mf-custom-components';
import { mapGetters } from 'vuex';
import { actions } from '@superpagos/mf-store';

export default {
    name: 'AppModalSession',
    props: {
        message: { type: String, required: true, default: '' },
        username: { type: String, required: true, default: '' },

    },
    data() {
        return {
            loading: false,
        };
    },

    components: {
        AppButton,
    },
    methods: {
        show() {
            this.$refs.refReviewModalSession.show();
        },
        close() {
            this.$refs.refReviewModalSession.hide();
        },
        async closeSession() {

            let data = {
					username:this.username
				};
            this.$store
				.dispatch(actions.CLOSE_ALL_SESSION,data )
				.then(result => {
					this.close();
                    this.appNotify.success(result.message);
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
					this.errConfirm = err;
          this.otpError = err.message
					this.appNotify(err.message);
				});

        }
    },
    computed: {
		...mapGetters(['user'])
	}

};
</script>

<style lang="scss">
.box-add-data {
    background: #fff;
    border-radius: 20px;
    bottom: 135px;
    padding: 20px;
    left: 35px;
}

.appInfo-title-secondary {
    background: linear-gradient(90deg, #00ceee -7.02%, #722ae6 87.46%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media (max-width: 800px) {
    .hide-on-mobile {
        display: none;
        visibility: hidden;
    }
}
</style>
