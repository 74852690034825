import "./set-public-path";
import Vue from "vue";
import Vuex from "vuex";
import singleSpaVue from "single-spa-vue";

import App from "./App.vue";
import { AppNotify } from "@superpagos/mf-custom-components";
import store from "@superpagos/mf-store";
import BootstrapVue from "bootstrap-vue";
import router from "./router";
import VueApexCharts from 'vue-apexcharts'

// Init Boot files
import { initServices} from "@/boot/services";
import { initSentry} from "@/boot/sentry";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(VueApexCharts);
Vue.component("app-notify", AppNotify);
Vue.component("app-apexchart", VueApexCharts);
var Store = store;

initServices({ vue: Vue, store });
initSentry({ vue: Vue });

Vue.directive("can", {
  bind(el, binding) {
    var user = Store.state.auth.user;
    if (user.Rol.permissions.indexOf(binding.value) == -1) {
      el.style.display = "none";
    }
  },
});

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    store,
    render: (h) => h(App),
    router,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

export { default as ToDoInput } from "./components/requirements/ToDoInput.vue";
export { default as AppMetaMap } from "./components/requirements/AppMetaMap.vue";
