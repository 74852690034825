var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("app-modal", { ref: _vm.id, attrs: { id: _vm.id, size: "sm" } }, [
    _c(
      "div",
      { staticClass: "flex flex-col items-center mt-4 md:!mt-0" },
      [
        _c("h1", { staticClass: "bold text-2xl mb-3" }, [
          _vm._v("Actualización disponible"),
        ]),
        _c("p", { staticClass: "text-base leading-5 tracking-tighter" }, [
          _vm._v(
            "Tienes una versión desactualizada, has click en el botón para actualizarla."
          ),
        ]),
        _c("app-button", {
          staticClass: "mt-5",
          attrs: { title: "Actualizar", clase: "primary px-5" },
          on: { click: _vm.reload },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }