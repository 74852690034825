<template>
	<div class="login">
		<form v-on:submit.prevent="submit">
			<div class="form-login">
				<div class="logo-login mx-auto">
					<img :src="brand.logo" width="100px" />
				</div>
				<h1 class="login-title text-center text-2xl leading-6">
					<strong>Primero cambia tu contraseña</strong>
				</h1>
				<div class="mt-4">
					<div class="relative">
						<AppInput
							:type="show_pass ? 'text' : 'password'"
							placeholder="Ej: Colombia123"
							labelClass="label-simple"
							v-model="form.password"
						>
						</AppInput>

						<button
							type="button"
							class="disabled-key"
							v-on:click="show_pass = !show_pass"
						>
							<i class="material-icons">{{
								show_pass ? 'block' : 'remove_red_eye'
							}}</i>
						</button>
					</div>

					<div class="box-btn mx-auto my-4">
						<app-button
							clase="primary w-auto px-5"
							title="Cambiar contraseña"
							type="submit"
							:isLoading="loading"
						>
						</app-button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import { actions } from '@superpagos/mf-store';
import { AppButton, AppInput } from '@superpagos/mf-custom-components';

export default {
	data() {
		return {
			loading: false,
			show_pass: false,
			need_token: false,
			step: 1,
			form: { password: '' }
		};
	},
	components: {
		AppButton,
		AppInput
	},
	methods: {
		submit() {
			if (this.loading) return;
			this.loading = true;

			this.$store
				.dispatch(actions.CHANGE_PASSWORD, this.form)
				.then(() => {
					if (window.localStorage.getItem('need2fa')) {
						this.$emit('2fa');
					} else {
						location.reload();
					}
				})
				.catch(err => {
					this.appNotify.error(err.message);
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	computed: {
		...mapGetters(['brand'])
	}
};
</script>

<style lang="scss">
.logo-login {
	display: flex;
	justify-content: center;
	padding: 20px 0;
}
.disabled-key {
	background: transparent;
	border: none;
	color: #2c3e51;
	position: absolute;
	top: 0px;
	right: 0px;
	width: 44px !important;
	z-index: 10000;
}
</style>
