<template>
	<div
		v-if="error"
		class="flex justify-between w-full py-3 px-4 bg-[#FFEEED] rounded-lg"
	>
		<img
			src="../assets/imgs/errorAlert.svg"
			alt="Error al diligenciar el valor de tratamiento de datos"
			class="w-[20%] md:w-[10%] h-auto"
		/>
		<p
			class="ml-3 text-xs md:text-sm text-[#FF002A] tracking-righter leading-4"
			>{{ error }}</p
		>
	</div>
</template>

<script>
export default {
	name: 'Refacil20AppErrorNotify',

	props: ['error'],

	data() {
		return {};
	},

	mounted() {},

	methods: {}
};
</script>

<style lang="scss" scoped></style>
