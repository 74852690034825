import * as Sentry from "@sentry/vue";
import {version} from "@/version";

export const initSentry = async ({ Vue }) => {
    if (process.env.NODE_ENV !== "production") return;
    // eslint-disable-next-line no-undef
    Sentry.init({
        Vue,
        dsn: "https://0ae794e03e12369f9df5ceb57cf0769c@o4507137578827776.ingest.us.sentry.io/4507137580859392",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        release: version,
        // Performance Monitoring
        tracesSampleRate: 0.1, //  Capture 10% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/app\.refacil\/\.co/, /^https:\/\/app\/\.qa\.refacil\/\.co/],
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}