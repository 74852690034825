import io from 'socket.io-client';
import { mutations } from '@superpagos/mf-store';
import { SOCKET_URL } from '@/config';

const EVENTS = {
	CONNECT: 'connect',
	USER_AUTH: 'user/auth',
	USER_BALANCE: 'user/balance',
	EXCEPTION: 'exception',
	DISCONNECT: 'disconnect',
	ERROR: 'error',
	RECONNECT_ERROR: 'reconnect_error'
};

let socket = null;

export const initSocket = ({ user, store }) => {
	if (!user) return;

	socket = io(SOCKET_URL, {
		auth: {
			token: `Bearer ${user.token}`
		},
		transports: ['websocket', 'polling']
	});

	// Events
	socket.on(EVENTS.CONNECT, () => {
		socket.emit(EVENTS.USER_AUTH);
	});

	socket.on(EVENTS.USER_BALANCE, (userUpdated) => {
		for (let i in userUpdated) {
			userUpdated[i] = userUpdated[i].replaceAll(/\$|,/gi, '');
		}
		store.commit(mutations.SET_USER, { ...user, ...userUpdated });
	});

	socket.on(EVENTS.EXCEPTION, data => {
		console.log('event', data);
	});

	socket.on(EVENTS.DISCONNECT, () => {
		console.log('Disconnected to socket');
	});

	socket.io.on(EVENTS.ERROR, error => {
		console.error(`Error connecting to socket: ${error}`);
	});

	socket.io.on(EVENTS.RECONNECT_ERROR, error => {
		console.error(`Error reconnecting to socket: ${error}`);
	});
};

export const disconnectSocket = () => {
	if (socket) socket.disconnect();
};
