// import { useApi } from "@superpagos/mf-services";

// const API = 'requirement'

import useRequirements from '@/hooks/useRequirements';

const useCheckDataTreatment = () => {
	const checkDataTreatment = user => {
		return user && !user.checkDataTreatment;
	};

	const getRequirement = async () => {
		let error = {};

		const DATA_TREATMENT_REQUIREMENT_ID = 17;

		const SECURITY_CATEGORY_ID = 5;
		const { getRequirement } = useRequirements();
		const result = await getRequirement(SECURITY_CATEGORY_ID);

		if (!result) {
			error.value = {
				message: 'Ha ocurrido un error, refresca la página e intenta de nuevo'
			};
		}

		let dataTreatmentReq = {};
		if (result) {
			dataTreatmentReq = result.requirements.find(
				req => req.id === DATA_TREATMENT_REQUIREMENT_ID
			);
		}

		return { dataTreatmentReq, error };
	};

	const updateCheckDataTreatment = async checkDataTreatment => {
		const { updateRequirement } = useRequirements();
		const { response, error } = await updateRequirement({
			requirements: checkDataTreatment
		});

		return { response, error };
	};

	return { checkDataTreatment, getRequirement, updateCheckDataTreatment };
};

export default useCheckDataTreatment;
