<template>
	<app-modal :id="id" :ref="id" size="sm">
		<div class="flex flex-col items-center mt-4 md:!mt-0">
			<h1 class="bold text-2xl mb-3">Actualización disponible</h1>
			<p class="text-base leading-5 tracking-tighter"
				>Tienes una versión desactualizada, has click en el botón para
				actualizarla.</p
			>
			<app-button
				class="mt-5"
				title="Actualizar"
				clase="primary px-5"
				@click="reload"
			></app-button>
		</div>
	</app-modal>
</template>

<script>
import { AppModal, AppButton } from '@superpagos/mf-custom-components';
import { checkBrandVersionWeb } from '../brandConfig';
import { mapGetters } from 'vuex';
import { specialRoutes } from '@superpagos/mf-services';
import useBrandUpdate from '../hooks/useBrandUpdate';
const { checkCanReload } = useBrandUpdate();

export default {
	name: 'BrandUpdate',

	data() {
		return {
			id: `brandUpdate-${Math.random().toString(36).substring(7)}`,
			maxReloads: 1
		};
	},

	components: {
		AppModal,
		AppButton
	},

	async mounted() {
		this.updateVersion();
	},

	methods: {
		reload() {
			// let { canReload } = checkCanReload(this.maxReloads);
			// canReload && window.location.reload(true); // Reload page
			window.location.reload(true); // Reload page
		},
		showModal() {
			// let isConsult = true;
			let { canReload } = checkCanReload(this.maxReloads);
			canReload && this.show(); // ShowModal
		},
		show() {
			setTimeout(() => {
				this.$refs[this.id].show();
			}, 500);
		},
		hide() {
			this.$refs[this.id].hide();
		},
		async updateVersion() {
			setTimeout(async () => {
				let isSpecialRoute = specialRoutes.some(e =>
					this.$route.path.includes(e)
				);
				if (isSpecialRoute) return;

				let needUpdate = await checkBrandVersionWeb(
					this.brand.versionWeb,
					this.globalVersion
				);
				needUpdate && this.showModal();
			}, 500);
		},
		async checkBrandVersionWebWatch() {}
	},

	computed: {
		...mapGetters(['brand', 'globalVersion'])
	},

	watch: {
		checkBrandVersionWebWatch() {
			this.updateVersion();
		},
		globalVersion() {
			this.updateVersion();
		},
		$route() {
			this.updateVersion();
		}
	}
};
</script>

<style lang="scss" scoped></style>
