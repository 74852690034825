var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.error
    ? _c(
        "div",
        {
          staticClass:
            "flex justify-between w-full py-3 px-4 bg-[#FFEEED] rounded-lg",
        },
        [
          _c("img", {
            staticClass: "w-[20%] md:w-[10%] h-auto",
            attrs: {
              src: require("../assets/imgs/errorAlert.svg"),
              alt: "Error al diligenciar el valor de tratamiento de datos",
            },
          }),
          _c(
            "p",
            {
              staticClass:
                "ml-3 text-xs md:text-sm text-[#FF002A] tracking-righter leading-4",
            },
            [_vm._v(_vm._s(_vm.error))]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }