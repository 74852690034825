var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "app-input",
    {
      attrs: {
        extraClass: "input-data-provider",
        labelClass: "label-data-provider",
        name: _vm.field.name || "",
        label: _vm.field.form.label,
        placeholder: _vm.field.form.placeholder,
        type: _vm.type || _vm.field.form.type,
        value:
          _vm.field.form.value &&
          _vm.field.form.value[0] &&
          _vm.field.form.value[0].value,
        disabled: _vm.isDisabled,
        link: _vm.field.form.url,
        text: _vm.field.name,
        length: _vm.field.form.maxlength,
        error: _vm.error || "",
      },
      on: { file: _vm.changeFile, input: _vm.updateValue },
    },
    [
      _vm.field.form.type == "select" || _vm.type == "select"
        ? _vm._l(_vm.options, function (option) {
            return _c(
              "option",
              { key: option.value, domProps: { value: option.value } },
              [_vm._v(" " + _vm._s(option.label) + " ")]
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }