function setOpacity(hexColor, opacity) {
	// Convert hex color to RGB
	const r = parseInt(hexColor?.slice(1, 3), 16);
	const g = parseInt(hexColor?.slice(3, 5), 16);
	const b = parseInt(hexColor?.slice(5, 7), 16);

	// Calculate new RGBA values based on opacity
	const newRgba = `rgba(${r}, ${g}, ${b}, ${opacity})`;

	// Convert the RGBA color back to a hex format
	const newHexColor =
		'#' +
		newRgba
			.slice(5, -1)
			.split(', ')
			.map(component => parseInt(component).toString(16).padStart(2, '0'))
			.join('');

	return newHexColor;
}

function changeBrightness(hexColor, brightness) {
	// Convert hex color to RGB
	const r = parseInt(hexColor?.slice(1, 3), 16);
	const g = parseInt(hexColor?.slice(3, 5), 16);
	const b = parseInt(hexColor?.slice(5, 7), 16);

	// Calculate new RGB values based on brightness
	const newR = Math.max(0, Math.min(255, r + brightness));
	const newG = Math.max(0, Math.min(255, g + brightness));
	const newB = Math.max(0, Math.min(255, b + brightness));

	// Convert the RGB color back to a hex format
	const hexRed = newR.toString(16).padStart(2, '0');
	const hexGreen = newG.toString(16).padStart(2, '0');
	const hexBlue = newB.toString(16).padStart(2, '0');
	const hexColorWithBrightness = `#${hexRed}${hexGreen}${hexBlue}`;

	return hexColorWithBrightness;
}

const colorsBrightness = {
	primaryTone4: -20,
	terciaryTone3: -10,
	primaryTone5: -40,
};


const colorsOpacity = {
	inputFocusColor: 73,
	primaryClearColor: 50,
	secondaryClearColor: 50,
	terciaryClearColor: 50,
	terciaryTone1: 50
};

const DEFAULT_TERCIARY_COLOR = '#8A0AE5';

function setColors(brand) {
	const { primaryColor, secondaryColor, config } = brand;

	document
		.querySelector('body')
		.style.setProperty('--primaryColor', primaryColor);

	document
		.querySelector('body')
		.style.setProperty('--secondaryColor', secondaryColor);

	document
		.querySelector('body')
		.style.setProperty(
			'--primaryTone4',
			config?.colors?.primaryTone4 ??
				changeBrightness(primaryColor, colorsBrightness.primaryTone4)
		);

	document
		.querySelector('body')
		.style.setProperty(
			'--primaryTone5',
			config?.colors?.primaryTone5 ??
				changeBrightness(primaryColor, colorsBrightness.primaryTone5)
		);

	document
		.querySelector('body')
		.style.setProperty(
			'--inputFocusColor',
			config?.colors?.inputFocusColor ??
				setOpacity(primaryColor, colorsOpacity.inputFocusColor)
		);

	document
		.querySelector('body')
		.style.setProperty(
			'--primaryClearColor',
			config?.colors?.primaryClearColor ??
				setOpacity(primaryColor, colorsOpacity.primaryClearColor)
		);
	document
		.querySelector('body')
		.style.setProperty(
			'--secondaryClearColor',
			config?.colors?.secondaryClearColor ??
				setOpacity(secondaryColor, colorsOpacity.secondaryClearColor)
		);
	document
		.querySelector('body')
		.style.setProperty(
			'--terciaryClearColor',
			config?.colors?.terciaryClearColor ??
				setOpacity(config?.colors?.terciaryColor, colorsOpacity.terciaryClearColor)
		);

	document
		.querySelector('body')
		.style.setProperty(
			'--terciaryColor',
			config?.colors?.terciaryColor ?? DEFAULT_TERCIARY_COLOR
		);

	document
		.querySelector('body')
		.style.setProperty(
			'--terciaryTone3',
			config?.colors?.terciaryTone3 ??
				changeBrightness(
					config?.colors?.terciaryColor ?? DEFAULT_TERCIARY_COLOR,
					colorsBrightness.terciaryTone3
				)
		);
	document
		.querySelector('body')
		.style.setProperty(
			'--terciaryTone1',
			config?.colors?.terciaryTone1 ??
				setOpacity(
					config?.colors?.terciaryColor ?? DEFAULT_TERCIARY_COLOR,
					colorsOpacity.terciaryTone1
				)
		);
	document
		.querySelector('body')
		.style.setProperty(
			'--primaryLinearColor',
			config?.skin?.active?.primaryLinearColor || primaryColor
		);
	document
		.querySelector('body')
		.style.setProperty(
			'--secondaryLinearColor',
			config?.skin?.active?.secondaryLinearColor || config?.colors?.terciaryColor
		);
}

export default setColors;
