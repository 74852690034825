var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "refReviewModalSession",
      attrs: {
        id: "reviewModalSession",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        size: "lg",
        "modal-class": "modal-bg-transparent",
      },
    },
    [
      _c("div", { staticClass: "box-add-data" }, [
        _c("div", { staticClass: "col-12 row p-0 m-0" }, [
          _c(
            "div",
            { staticClass: "col-5 p-0 d-flex justify-content-center" },
            [
              _c("img", {
                staticClass: "mr-2",
                staticStyle: { height: "177px" },
                attrs: {
                  src: require("@/assets/imgs/login/interrogacionModal.png"),
                  alt: "interrogacion",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "col-7 p-0 m-0 row" }, [
            _c(
              "h3",
              {
                staticClass:
                  "appInfo-title-secondary text-[28px] font-bold leading-11 mt-2 text-left",
              },
              [
                _vm._v(" ¡Ups!"),
                _c("br"),
                _vm._v(" Debes cerrar sesión en tus otros dispositivos "),
              ]
            ),
            _c(
              "p",
              {
                staticClass:
                  "font-normal text-base text-[#000000] text-[24px] m-0 mr2 sm:text-xs",
              },
              [_vm._v(" " + _vm._s(_vm.message) + " ")]
            ),
            _c(
              "div",
              { staticClass: "col-12 row justify-content-center p-0" },
              [
                _c(
                  "div",
                  { staticClass: "col-7 row justify-content-center p-0" },
                  [
                    _c("app-button", {
                      attrs: {
                        extraClass: "primary-gradient px-3 my-5",
                        clase: "primary",
                        title: "Cerrar sesiones",
                      },
                      on: { click: _vm.closeSession },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-5 row justify-content-center" },
                  [
                    _c("app-button", {
                      attrs: {
                        extraClass: "primary-gradient  px-6 my-5",
                        clase: "secondary-outline",
                        title: "Cancelar",
                        width: "50px",
                      },
                      on: { click: _vm.close },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }