// import useApi from '@/hooks/useApi';
import { useApi } from '@superpagos/mf-services';
const API = 'requirement';
export default function useRequirements() {
	const getRequirements = async () => {
		const { response, request } = useApi(API);
		await request('/requirements/getCategories', {});
		return response.value;
	};

	const getRequirement = async id => {
		const { response, request } = useApi(API);
		await request('/requirements/getByCategory', { id });
		return response.value;
	};

	const updateRequirement = async data => {
		const { response, request, error } = useApi(API);
		await request('/requirements/update', data);
		return { response: response.value, error };
	};

	const generateOtp = async () => {
		const { response, request, error } = useApi(API);
		await request('/requirements/generate-otp-upgrade');
		return { response: response.value, error };
	}

	const updateFile = async data => {
		const { response, requestMultipart, error } = useApi(API);
		await requestMultipart('requirements/upload/file', data);
		return { response: response.value, error };
	};

	const getUserRol = async () => {
		const { response, request, error } = useApi(API);
		await request('/requirements/getUserRole', {});
		return { response: response.value, error };
	}

	return { getRequirements, getRequirement, updateRequirement, updateFile, generateOtp, getUserRol };
}
