import useBrandConfig from './hooks/useBrandConfig';
import setColors from './services/setColors';
import Store, { actions } from '@superpagos/mf-store';


const { getBrand, getBrandVersionWeb } = useBrandConfig();

export const initBrand = async () => {
	const { error, brand } = await getBrand();

	// Error handler
	if (error) console.error('Error getting brand: ', error);

	// Brand config
	// 1. Set colors
	setColors(brand);
};

export const checkBrandVersionWeb = async (brandVersionWeb, globalVersion) => {
	if (!Store.getters.user || !globalVersion) return false;
	let versionWebAux = '';
	let needUpdate = false;

	// Get brand version web from db
	const { versionWeb, error } = await getBrandVersionWeb();

	// If error getting brand version web, use brandVersionWeb from store
	if (error) versionWebAux = brandVersionWeb.split('.');
	else versionWebAux = versionWeb.split('.');

	// If error getting brand version web and brandVersionWeb from store are empty, return false
	if (!versionWebAux) return false;

	// Split global version to compare
	let globalVersionAux = globalVersion.split('.');

	// validate if each value of versions are the same and not 'x' default value
	versionWebAux.map((value, index) => {
		if (globalVersionAux[index] !== 'x' && globalVersionAux[index] != value)
			needUpdate = true;
	});
	// TODO: Implements NEED_APP_UPDATE action
	// No existe el metodo needAppUpdate en el store
	Store.dispatch(actions.NEED_APP_UPDATE, needUpdate);
	return needUpdate;
};
