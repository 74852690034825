const userBrandUpdate = () => {
	const checkCanReload = maxReloads => {
		let canReload = false;
		const reloadCount = localStorage.getItem('reloadCount') || 0;

		const reloadInterval = 2 * 60 * 60 * 1000; // Reload interval in milliseconds ( 2 hours )

		if (reloadCount < maxReloads) {
			// Show update modal
			// Only count reloads increment if the user clicks the update button
			// !isConsult && localStorage.setItem('reloadCount', +reloadCount + 1);
			localStorage.setItem('reloadCount', +reloadCount + 1);

			// Set last reload time (consult or reload)
			const currentTime = new Date().getTime();
			localStorage.setItem('lastReloadTime', currentTime);

			canReload = true;
		} else {
			// Check if the reload interval has passed
			const lastReloadTime = localStorage.getItem('lastReloadTime') || 0;
			const currentTime = new Date().getTime();
			if (currentTime - lastReloadTime > reloadInterval) {
				// Reset the reload count and last reload time
				localStorage.setItem('reloadCount', 0);
				// !isConsult && localStorage.setItem('lastReloadTime', currentTime);
				localStorage.setItem('lastReloadTime', currentTime);

				// Show update modal
				canReload = true;
			} else {
				// Display an error message or do nothing
				console.warn(
					'Maximum number of reloads reached. Please try again later.'
				);
			}
		}
		return { canReload };
	};

	const cleanReloadIntents = () => {
		// Delete reloadCount and lastReloadTime from localStorage
		localStorage.removeItem('reloadCount');
		localStorage.removeItem('lastReloadTime');
	};

	return { checkCanReload, cleanReloadIntents };
};

export default userBrandUpdate;
