<template>
	<div v-if="field" class="flex justify-center items-center flex-col mt-1">
		<h2 class="bold text-lg">{{ field.form.label }}</h2>
		<p class="text-xs text-gray-400 mb-2">{{ field.form.description }}</p>
		<div
			class="text-center"
			v-if="
				!statusProcess &&
				!metamapDocumentValue &&
				(!metamapStatus ||
					((metamapStatus === 'expired' ||
						metamapStatus === 'rejected' ||
						metamapStatus === 'rejectedDifference') &&
						isMetamapActive))
			"
		>
			<button class="appearance-none" @click="startProcessStatus = true">
				<mati-button
					id="r-mati-button"
					:clientid="field.form.clientid"
					:flowId="field.form.flowId"
					:metadata="
						JSON.stringify({
							...field.form.value[0],
							requirementId: field.id,
							...metadata
						})
					"
				/>
			</button>
			<div v-if="metamapMessages[metamapStatus]" class="mt-2">
				<p class="text-center text-lg tracking-tighter leading-6">
					{{ metamapMessages[metamapStatus].message }}
				</p>
			</div>
			<div class="mt-2" v-else
				><p class="text-center text-lg tracking-tighter leading-6">
					Ten en cuenta que tu identificación debe corresponder con los datos
					ingresados en tu autorregistro
				</p></div
			>
		</div>

		<div v-if="statusProcess">
			<p class="text-center text-lg tracking-tighter leading-6">
				{{ statuses[statusProcess] }}
			</p>
		</div>

		<div
			v-if="
				metamapStatus == 'started' ||
				(field.form.value[0] &&
					field.form.value[1] &&
					field.form.value[1].status == 'verification_started') ||
				!isMetamapActive
			"
		>
			<p class="text-center text-lg tracking-tighter leading-6">
				Tienes un proceso activo, espera unos minutos para volver a iniciar una
				nueva validación.
			</p>
		</div>
		<div v-else-if="metamapStatus == 'verified' || metamapDocumentValue">
			<p class="text-center text-lg tracking-tighter leading-6">
				{{
					metamapDocumentValue
						? 'Ya firmaste el documento'
						: 'Ya estás validado'
				}}
			</p>
		</div>
		<div v-else-if="metamapStatus == 'reviewNeeded'">
			<p class="text-center text-lg tracking-tighter leading-6">
				Estamos verificando tus datos, te notificaremos cuando todo esté listo
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AppMetaMap',
	props: {
		field: { type: Object, default: null },
		metadata: { default: {} }
	},
	data() {
		return {
			isMetamapActive: true,
			/**
			 * Statuses:
			 * 1. In progress,
			 * 2. Finished
			 */
			statuses: {
				inProgress: 'Flujo en proceso',
				finished:
					'Gracias, tus datos fueron enviados exitosamente y estan siendo validados'
			},
			statusProcess: null,
			startProcessStatus: false,
			metamapMessages: {
				rejected: {
					message: 'No hemos podido validar tu identidad, inténtalo de nuevo'
				},
				expired: {
					message: 'Tu proceso de validación a expirado, inténtalo de nuevo'
				},
				rejectedDifference: {
					message: `Los datos utilizados en la validación biométrica NO coinciden con los datos utilizados en tu autorregistro. Verifica y actualiza tus datos de registro o comunícate con nuestro centro de atención al cliente a través de WhatsApp 317 230 9003`
				}
			}
		};
	},
	computed: {
		metamapStatus() {
			return !this.field.form.value[1]
				? false
				: this.field.form.value[1]?.status;
		},
		metamapDocumentValue() {
			return this.field.form?.value[1]?.value;
		}
	},
	mounted() {
		this.setUpMatiCallbacks();
	},
	methods: {
		setUpMatiCallbacks() {
			const button = document.getElementById('r-mati-button');

			if (button) {
				// setup callbacks
				button.addEventListener('mati:userStartedSdk', () => {
					if (this.startProcessStatus) this.statusProcess = 'inProgress';
				});
				button.addEventListener('mati:userFinishedSdk', () => {
					if (this.startProcessStatus) this.statusProcess = 'finished';
				});
				button.addEventListener('mati:exitedSdk', () => {
					if (this.startProcessStatus) this.statusProcess = null;
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped></style>
