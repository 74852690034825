var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.field
    ? _c(
        "div",
        { staticClass: "flex justify-center items-center flex-col mt-1" },
        [
          _c("h2", { staticClass: "bold text-lg" }, [
            _vm._v(_vm._s(_vm.field.form.label)),
          ]),
          _c("p", { staticClass: "text-xs text-gray-400 mb-2" }, [
            _vm._v(_vm._s(_vm.field.form.description)),
          ]),
          !_vm.statusProcess &&
          !_vm.metamapDocumentValue &&
          (!_vm.metamapStatus ||
            ((_vm.metamapStatus === "expired" ||
              _vm.metamapStatus === "rejected" ||
              _vm.metamapStatus === "rejectedDifference") &&
              _vm.isMetamapActive))
            ? _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    staticClass: "appearance-none",
                    on: {
                      click: function ($event) {
                        _vm.startProcessStatus = true
                      },
                    },
                  },
                  [
                    _c("mati-button", {
                      attrs: {
                        id: "r-mati-button",
                        clientid: _vm.field.form.clientid,
                        flowId: _vm.currentFlowId,
                        metadata: JSON.stringify({
                          ..._vm.field.form.value[0],
                          requirementId: _vm.field.id,
                          ..._vm.metadata,
                        }),
                      },
                    }),
                  ],
                  1
                ),
                _vm.metamapMessages[_vm.metamapStatus]
                  ? _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-lg tracking-tighter leading-6",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.metamapMessages[_vm.metamapStatus].message
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _c("div", { staticClass: "mt-2" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center text-lg tracking-tighter leading-6",
                        },
                        [
                          _vm._v(
                            " Ten en cuenta que tu identificación debe corresponder con los datos ingresados en tu autorregistro "
                          ),
                        ]
                      ),
                    ]),
              ])
            : _vm._e(),
          _vm.statusProcess
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-center text-lg tracking-tighter leading-6",
                  },
                  [_vm._v(" " + _vm._s(_vm.statuses[_vm.statusProcess]) + " ")]
                ),
              ])
            : _vm._e(),
          _vm.metamapStatus == "started" ||
          (_vm.field.form.value[0] &&
            _vm.field.form.value[1] &&
            _vm.field.form.value[1].status == "verification_started") ||
          !_vm.isMetamapActive
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-center text-lg tracking-tighter leading-6",
                  },
                  [
                    _vm._v(
                      " Tienes un proceso activo, espera unos minutos para volver a iniciar una nueva validación. "
                    ),
                  ]
                ),
              ])
            : _vm.metamapStatus == "verified" || _vm.metamapDocumentValue
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-center text-lg tracking-tighter leading-6",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.metamapDocumentValue
                            ? "Ya firmaste el documento"
                            : "Ya estás validado"
                        ) +
                        " "
                    ),
                  ]
                ),
              ])
            : _vm.metamapStatus == "reviewNeeded"
            ? _c("div", [
                _c(
                  "p",
                  {
                    staticClass:
                      "text-center text-lg tracking-tighter leading-6",
                  },
                  [
                    _vm._v(
                      " Estamos verificando tus datos, te notificaremos cuando todo esté listo "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }