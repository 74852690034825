const LIMIT_SHOW_DATE = new Date('2024-04-12');
const LIMIT_SHOW_DATE_TIMESTAMP = LIMIT_SHOW_DATE.getTime();

import getPopUpService from "../services/getPopUp.service";

const usePopUpModal = () => {
	// obtain date in currentDate format

	const validateShowModal = ({intervalTime = 0}) => {
		let showModal = false;

		let popUpModalLastShow = localStorage.getItem('popUpModalLastShow');

		if (popUpModalLastShow === null) {
			showModal = true;
			let date = new Date();
			let currentDate = date.getTime();
			if (LIMIT_SHOW_DATE_TIMESTAMP > currentDate) {
				localStorage.setItem('popUpModalLastShow', currentDate);
			}
		} else {
			let date = new Date();
			let currentDate = date.getTime();
			let popUpModalLastShow = localStorage.getItem('popUpModalLastShow');
			let intervalTimeNumber = parseInt(popUpModalLastShow);

			if (LIMIT_SHOW_DATE_TIMESTAMP > currentDate) {
				// show modal if currentDate is greater than intervalTime
				if (currentDate - intervalTimeNumber > intervalTime) {
					showModal = true;
					localStorage.setItem('popUpModalLastShow', currentDate);
				}
			}
		}
		return showModal;
	};

	const getPopUpModal = (data) => {
		return getPopUpService(data).then((response) => {
			return response
		}).catch((error) => {
			console.log('🚀 ~ getPopUpModal ~ error:', error)
			throw new Error(error)
		});
	}

	return { validateShowModal, getPopUpModal };
};

export default usePopUpModal;
