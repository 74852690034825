var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "validate-pin-dialog" }, [
    _c("div", { staticClass: "box-add-data text-center" }, [
      _c("h1", { staticClass: "bold" }, [
        _vm._v("¡Gracias por confiar en nosotros!"),
      ]),
      _c(
        "p",
        { staticClass: "mt-5 vpd-p1" },
        [
          _vm._v(" Hemos enviado un "),
          _c("strong", [_vm._v("PIN")]),
          _vm._v(" a tu celular "),
          _vm.errConfirm && _vm.errConfirm.data
            ? [
                _c("strong", [
                  _vm._v("(" + _vm._s(_vm.errConfirm.data.cellphoneMask) + ")"),
                ]),
              ]
            : _vm._e(),
          _vm._v(" y a tu correo, escríbelo acá "),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "py-[1rem] md:py-[1rem]" },
        [
          _c("app-otp-input", {
            attrs: {
              primaryColor: "var(--primaryColor)",
              "otp-error": _vm.otpError,
              "otp-loading": _vm.loading,
            },
            on: { sendCode: _vm.resendPin },
            model: {
              value: _vm.form.pin,
              callback: function ($$v) {
                _vm.$set(_vm.form, "pin", $$v)
              },
              expression: "form.pin",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex flex-row justify-around box-btn mx-auto" },
        [
          _c("app-button", {
            attrs: {
              extraClass: "neutral w-auto px-5 mr-4",
              clase: "secondary",
              title: "Lo haré luego",
              width: "122px",
            },
            on: { click: _vm.close },
          }),
          _c("app-button", {
            attrs: {
              extraClass: "primary-gradient w-auto px-5",
              clase: "primary",
              title: "Confirmar",
              width: "119px",
              isLoading: _vm.loading,
            },
            on: { click: _vm.confirmPin },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }