export const disableRightClick = () => {
	document.addEventListener('contextmenu', event => {
		let env = process.env.NODE_ENV;
		if (env === 'production') {
			event.preventDefault();
		}
	});
};

export const disableInspectPage = () => {
	document.addEventListener('keydown', function (event) {
		let env = process.env.NODE_ENV;
		if (event.key === 'F12' && env === 'production') {
			event.preventDefault();
		}
	});
};